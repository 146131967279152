<template>
  <!-- 课程学习-已加入课程 -->
  <div class="main-contain_con">
    <div class="contain_up">
      <div class="title_con">
        <div class="title_text_border">
          <div class="left_circle"></div>
          <div class="right_circle"></div>
          <div class="title_text">{{ courseSemesterInfo.name || "" }}</div>
          <div class="time_text">
            开课时间: {{ courseSemesterInfo.start_time | dataformat }} ~
            {{ courseSemesterInfo.end_time | dataformat }}
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="left_cont">
        <div class="introduction">
          <el-row class="elrow">
            <el-col :span="10" class="elcol-left">
              <el-image
                :class="courseSemesterInfo.cover ? 'elimage' : 'elimage1'"
                :src="
                  courseSemesterInfo.cover ? courseSemesterInfo.cover : image1
                "
                @error="handleError"
              />
              <el-image
                class="statusimg"
                :src="statusImg"
                style="max-height: 330px"
              />
            </el-col>
            <el-col :span="14" class="elcol-right">
              <div class="intro-title">
                <div class="item">
                  <div class="item-name">所属学科：</div>
                  <div class="item-text">
                    {{ courseSemesterInfo.subject_config_info?.name || "" }}
                  </div>
                </div>
                <div class="item">
                  <div class="item-name">所属性质：</div>
                  <div class="item-text">
                    {{ courseSemesterInfo.course_type_config_info?.name || "" }}
                  </div>
                </div>
                <div class="item">
                  <div class="item-name">课程类别：</div>
                  <div class="item-text">
                    {{
                      courseSemesterInfo.course_classify_config_info?.name || ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <div class="item-name">主讲老师：</div>
                  <div class="item-text">
                    {{ courseSemesterInfo.leading_user_info?.toString() }}
                  </div>
                </div>
              </div>
              <div class="course-item">
                <div class="left-title">
                  <div class="coursename">课程介绍：</div>
                </div>
                <div class="right-text">
                  <div class="text">{{ courseSemesterInfo.brief || "" }}</div>
                </div>
              </div>
              <div class="course-item">
                <div class="left-title">
                  <div class="coursename">课程目标：</div>
                </div>
                <div class="right-text">
                  <div class="text">{{ courseSemesterInfo.target || "" }}</div>
                </div>
              </div>
              <div class="course-item">
                <div class="left-title">
                  <div class="coursename">教学团队：</div>
                </div>
                <div class="right-text">{{ teachteam }}</div>
              </div>
              <div class="semester">
                <div class="title">
                  开课 <span class="num">{{ courseInfo.nper }}</span> 学期
                </div>
                <el-select
                  v-model="courseSemesterSn"
                  class="select"
                  placeholder="请选择"
                  @change="switcCourse"
                >
                  <el-option
                    v-for="item in courseSemesters"
                    :key="item.id"
                    class="select_opt"
                    :label="item.name"
                    :value="item.sn"
                  >
                    <span class="opt_name">{{ item.name }}</span>
                    <span class="opt_status">{{
                      item.status | statusFilter
                    }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="bottom-title">
                <div class="item">
                  <div class="num">{{ courseSemesterInfo.credit || 0 }}</div>
                  <div class="score">学分</div>
                </div>
                <div class="item">
                  <div class="num">
                    {{ courseSemesterInfo.class_hours || 0 }}
                  </div>
                  <div class="score">学时</div>
                </div>
                <div class="item">
                  <div class="num">
                    {{ courseSemesterInfo.join_count || 0 }}
                  </div>
                  <div class="score">加入人数</div>
                </div>
                <div class="item">
                  <div class="num">
                    {{ courseSemesterInfo.view_count || 0 }}
                  </div>
                  <div class="score">点击率</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="bottom">
          <div class="bottom_con">
            <div class="tabbt-contain">
              <div
                :class="activebt === index ? 'bt btactive' : 'bt'"
                v-for="(item, index) in btlist"
                :key="index"
                @click.stop="btclick(index, item)"
              >
                <!-- <el-image class="bt-icon" :src="item.icon2" /> -->
                <div
                  :class="
                    activebt == index ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <component :is="componentId" ref="mycomponent"></component>
          </div>
        </div>
      </div>
      <div class="right_cont" v-if="resCourseList.length > 0">
        <div class="title">相关课程</div>
        <div class="rec_course">
          <div
            v-for="(item, inx) in resCourseList"
            :key="inx"
            class="course_block"
            @click="to_detail(item)"
          >
            <div class="course_block_img">
              <el-image :src="item.cover" />
            </div>
            <el-tooltip
              :content="item.name"
              placement="top"
              effect="light"
              v-if="item.flag"
            >
              <div class="course_block_text">{{ item.name }}</div>
            </el-tooltip>
            <div class="course_block_text" v-else>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* 页面 - 组件 */
import Courseoutline from "./components/courseoutline.vue";
import Virtualexperiment from "./components/virtualexperiment.vue";
import Homeworktest from "./components/homeworktest.vue";
import Courseinformation from "./components/courseinformation.vue";
import Discussion from "./components/discussion.vue";
/* 方法 - 接口 */
import {
  getcourseinfo,
  getCourseSemesterList,
  getCourseSemesterInfo,
  getRecCourseSemesterList,
} from "@/api/course";
import { getcourseteacherlist } from "@/api/courseteacher";
import dayjs from "dayjs";
import { getToken } from "@/utils/auth";
const statusMap = {
  0: "未开始",
  1: "进行中",
  2: "已结束",
};

export default {
  name: "courselearning",
  components: {
    Courseoutline,
    Virtualexperiment,
    Homeworktest,
    Courseinformation,
    Discussion,
  },
  inject: ["reload"],
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm");
    },
    statusFilter(status) {
      return statusMap[status];
    },
  },
  data() {
    return {
      collectstate: 0,
      courseSn: "", // 课程编号
      courseInfo: {}, // 课程信息
      courseKey: "", // 加入课程的钥匙
      courseSemesterId: "", // 课程课期ID
      courseSemesterSn: "", // 课程课期ID
      courseSemesterInfo: {}, // 课程课期信息
      courseSemesters: [], // 课程课期列表
      componentId: "Courseoutline",

      teachteam: "",
      statusImg: "",
      statusImg1: require("@/assets/coursedetails/st_not_start.png"),
      statusImg2: require("@/assets/coursedetails/st_ing.png"),
      statusImg3: require("@/assets/coursedetails/st_over.png"),
      testimage: require("@/assets/test.png"),
      image1: require("@/assets/defaultImage.png"),

      btlist: [
        {
          icon: require("@/assets/coursedetails/课程管理 (1).png"),
          icon2: require("@/assets/coursedetails/new/课程管理.png"),
          name: "课程大纲",
          component: "Courseoutline",
        },
        // {
        //   icon: require("@/assets/coursedetails/实验 烧杯 2.png"),
        //   icon2: require("@/assets/coursedetails/new/实验 烧杯 2.png"),
        //   name: "虚拟仿真实验",
        //   component: "Virtualexperiment",
        // },
        {
          icon: require("@/assets/coursedetails/考试 (1).png"),
          icon2: require("@/assets/coursedetails/new/考试.png"),
          name: "作业考试",
          component: "Homeworktest",
        },
        {
          icon: require("@/assets/coursedetails/图层 61.png"),
          icon2: require("@/assets/coursedetails/new/图层 61.png"),
          name: "课程资料",
          component: "Courseinformation",
        },
        {
          icon: require("@/assets/coursedetails/问答2.png"),
          icon2: require("@/assets/coursedetails/new/互动管理.png"),
          name: "问答讨论",
          component: "Discussion",
        },
      ],
      activebt: 0,
      resCourseList: [],
    };
  },
  created() {
    this.activebt = 0;
    this.courseSn = decodeURIComponent(this.$route.query.course_sn);
    if (getToken()) {
      this.initdata();
    } else {
      this.$router.push({
        path: "/home/wisdompracticeteaching/coursedetails",
        query: {
          course_sn: this.courseSn,
        },
      });
    }
  },
  watch: {
    resCourseList: {
      handler(newVal) {
        if (!this.isMobile()) {
          let resDis = document.querySelector(".left_cont");
          let resDown = document.querySelector(".contain_down");
          console.log('resDown', resDown);
          if (newVal.length > 0) {
            resDis.style["width"] = "calc(100% - 390px)";
            let dis = document.querySelector(".right_cont");
            resDown.style["width"] = "1640px";
            if (dis) {
              dis.style["width"] = "390px";
            }
          } else {
            if (resDis) {
              resDis.style["width"] = "100%";
              resDown.style["width"] = "1200px";
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 判断手机端还是pc端
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    handleError(e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image1;
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    // 初始化页面
    initdata() {
      if (this.$route.query.course_semester_sn) {
        this.courseSemesterSn = decodeURIComponent(
          this.$route.query.course_semester_sn
        );
        this.setCourseSemester();
      } else {
        this.getcourseinfo();
      }
    },
    // 获取课程详细信息
    getcourseinfo() {
      const params = { course_sn: this.courseSn };
      getcourseinfo(params)
        .then((res) => {
          if (res.code === 0 && res.data) {
            console.log("课程详细信息", res.data);
            this.courseInfo = res.data.course_info; // 自定义对象接收课程详情
            this.courseSemesterId = this.courseInfo.newest_course_semester_id;
            this.courseSemesterInfo = res.data.info; // 自定义对象接收课程课期详情
            this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn;
            this.courseKey = this.courseSemesterSn;
            // 判断当前课期状态
            if (res.data.info?.course_status == 0) {
              this.statusImg = this.statusImg1;
            } else if (res.data.info?.course_status == 1) {
              this.statusImg = this.statusImg2;
            } else {
              this.statusImg = this.statusImg3;
            }
            this.is_collect = res.data.info?.is_collect; // 是否收藏
            this.collect_count = res.data.info?.collect_count || 0; // 收藏数
            // 课程地址定位中心点
            this.centerPoint = {
              address: res.data.info?.address?.address,
              lng: res.data.info?.address?.longitude,
              lat: res.data.info?.address?.latitude,
            };
            this.$refs.mycomponent.initdata(
              this.courseSemesterInfo,
              this.courseKey
            );
            this.getCourseSemesterList(); // 获取课程的课期
            this.getRecCourseList(); // 获取相关课程
            this.getteacherlist(); // 获取教师列表
          } else if (res.code === 3) {
            // this.$message.warning('该课程不存在或已下架!')
            this.$router.push({
              path: "/home/wisdompracticeteaching/mainpage",
            });
          }
        })
        .catch(() => {});
    },
    // 获取课程课期列表
    getCourseSemesterList() {
      const params = {
        course_sn: this.courseInfo.course_sn,
        page: 1,
        per_page: 100,
      };
      getCourseSemesterList(params).then((res) => {
        if (res.code === 0 && res.data) {
          this.courseSemesters = res.data.map((item) => {
            return {
              id: item.course_semester_id,
              sn: item.course_semester_sn,
              name:
                item.start_year +
                "-" +
                item.end_year +
                " 第" +
                item.semester_nper +
                "学期",
              status: item.time_status,
            };
          }); // 课期列表
        }
      });
    },
    // 切换课期
    switcCourse(e) {
      this.courseSemesterSn = e;
      console.log("****************");
      this.setCourseSemester();
    },
    // 设置课期
    setCourseSemester() {
      const params = { course_semester_sn: this.courseSemesterSn };
      // 获取课期详情
      getCourseSemesterInfo(params).then((res) => {
        if (res.code === 0 && res.data) {
          if (Number(res.data.info.is_join) === 1) {
            this.courseInfo = res.data.course_info; // 自定义对象接收课程详情
            this.courseSemesterId = this.courseInfo.newest_course_semester_id;
            this.courseSemesterInfo = res.data.info; // 自定义对象接收课程课期详情
            this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn;
            this.courseKey = this.courseSemesterSn;
            // 判断当前课期状态
            if (res.data.info?.course_status == 0) {
              this.statusImg = this.statusImg1;
            } else if (res.data.info?.course_status == 1) {
              this.statusImg = this.statusImg2;
            } else {
              this.statusImg = this.statusImg3;
            }
            this.is_collect = res.data.info?.is_collect; // 是否收藏
            this.collect_count = res.data.info?.collect_count || 0; // 收藏数
            // 课程地址定位中心点
            this.centerPoint = {
              address: res.data.info?.address?.address,
              lng: res.data.info?.address?.longitude,
              lat: res.data.info?.address?.latitude,
            };
            this.$refs.mycomponent.initdata(
              this.courseSemesterInfo,
              this.courseKey
            );
            this.getCourseSemesterList(); // 获取课程的课期
            this.getRecCourseList(); // 获取相关课程
            this.getteacherlist(); // 获取教师
          } else {
            this.$router.push({
              path: "/home/wisdompracticeteaching/coursedetails",
              query: {
                course_sn: this.courseSn,
                course_semester_sn: this.courseSemesterSn,
              },
            });
          }
        }
      });
    },
    // 获取课程标签相关的课程列表
    getRecCourseList() {
      this.resCourseList = [];
      const params = {
        course_semester_sn: this.courseSemesterSn,
        page: 1,
        per_page: 10000,
      };
      getRecCourseSemesterList(params).then((res) => {
        if (res.code === 0) {
          res.data.data.map((item) => {
            if (item.name.length > 14) {
              item.flag = true;
            }
          });
          this.resCourseList = res.data.data;
        }
      });
    },

    btclick(name, component) {
      this.activebt = name;
      this.componentId = component.component;
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(
          this.courseSemesterInfo,
          this.courseKey
        );
      });
    },

    getteacherlist() {
      const params = {
        course_semester_sn: this.courseSemesterSn,
      };
      getcourseteacherlist(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            const arr = response.data.data.map((el) => el.name);
            this.teachteam = arr.join("、");
          }
        })
        .catch(() => {});
    },

    to_detail(item) {
      if (item.is_join == 1) {
        this.$router.push({
          path: "/home/wisdompracticeteaching/courselearning",
          query: {
            course_semester_sn: item.course_semester_sn,
          },
        });
        this.reload();
      } else {
        this.$router.push({
          path: "/home/wisdompracticeteaching/coursedetails",
          query: {
            course_semester_sn: item.course_semester_sn,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.semester {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
  .title {
    display: block !important;
    // color: #ffffff;
    font-size: 16px;
    // font-weight: bold;
    width: 120px;
    height: 40px;
    line-height: 40px;
    margin-right: 10px;
    .num {
      font-size: 30px;
      font-weight: 700;
    }
  }
}
/* 头部下拉选项 */
::v-deep .select {
  .el-input__inner {
    font-size: 15px;
    // font-weight: 700;
    border-radius: 20px;
  }
}
.select_opt {
  .opt_name {
    color: #333;
    font-size: 14px;
    line-height: 30px;
    float: left;
  }
  .opt_status {
    color: #2a7cec;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
    background-color: #dfdfdf;
    border-radius: 20px;
    float: right;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 640px) {
  .main-contain_con {
    margin-bottom: 140px;
    overflow: hidden;
    margin-top: 66px;

    .contain_up {
      background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
      background-size: 100% 100%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // height: 298px;

      .title_con {
        background: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px;

        .title_text_border {
          position: relative;
          min-width: 325px;
          height: 160px;
          border-top: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .left_circle {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(-100%, -50%);
          }

          .right_circle {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(100%, 50%);
          }

          .bt {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 151px;
            height: 40px;
            background: #3d84ff;
            border-radius: 20px;
            border-color: #3d84ff;
            opacity: none;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }

          .title_text {
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
          }

          .time_text {
            margin-top: 10px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    .contain_down {
      width: 100%;
      margin: 0px auto;
      // display: flex;
      // justify-content: center;

      .left_cont {
        // width: calc(100% - 300px);
      }

      .right_cont {
        // width: 300px;
        padding-left: 10px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          padding: 20px 10px 10px;
        }

        .rec_course {
          // width: 240px;
          // height: 1055px;
          // overflow-y: scroll;

          .course_block {
            // padding-top: 20px;
            cursor: pointer;
            float: left;
            width: calc((100% - 20px) / 2);
            margin-left: 5px;
            margin-right: 5px;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            // padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .introduction {
        // margin-top: 25px;
        // border-radius: 4px;

        .elrow {
          height: 100%;
          width: 100%;

          .elcol-left {
            position: relative;
            width: 100%;

            .elimage {
              width: 100%;
              min-height: 200px;
              border-radius: 5px;
            }
            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                left: 15%;
              }
            }

            .statusimg {
              width: 80px;
              height: 80px;
              position: absolute;
              left: -2px;
              top: -2px;
            }
          }

          .elcol-right {
            height: 100%;
            padding-left: 10px;
            width: 100%;

            .intro-title {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              align-items: center;

              .item {
                margin-top: 10px;
                // margin-right: 100px;
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .elimage {
                  width: 20px;
                  height: 20px;
                }

                .elimage2 {
                  width: 23px;
                  height: 23px;
                }

                .item-name {
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  white-space: nowrap;
                }

                .item-text {
                  font-size: 14px;
                  font-weight: 500;
                  color: #333333;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .item:nth-child(1),
              .item:nth-child(2) {
                margin-top: 0px;
              }
            }

            .course-item {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: baseline;
              overflow: hidden;

              .left-title {
                width: 90px;
                white-space: nowrap;

                .coursename {
                  margin-left: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  margin-right: 10px;
                }
              }

              .right-text {
                position: relative;
                font-size: 14px;
                width: calc(100% - 90px);

                .text {
                  font-size: 14px;
                  line-height: 25px;
                  font-weight: 400;
                  color: #333333;
                  max-height: 150px;
                  overflow-y: auto;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                  word-wrap: break-word;
                }

                .morebt {
                  position: absolute;
                  font-size: 16px;

                  font-weight: 400;
                  color: #3d84ff;
                  bottom: 0px;
                  right: 0px;
                  user-select: none;
                  cursor: pointer;
                }
              }

              .right-text2 {
                margin-left: -80px;
              }
            }

            .bottom-title {
              margin-left: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .text {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                word-break: break-word;
                /* 英文-字母 */
                word-wrap: break-word;
                /* 英文-单词 */
                white-space: pre-line;
                /* 中文 */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bottom_con {
        width: 100%;
        min-height: 400px;

        .tabbt-contain {
          border-bottom: 1px solid #cccccc;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bt {
            // margin-right: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            user-select: none;
            cursor: pointer;

            .bt-icon {
              margin-right: 6px;
            }

            .bt-name {
              font-size: 14px;
              font-weight: 400;
              color: #222222;
            }

            .bt-name-active {
              color: #3d84ff;
            }
          }

          .btactive {
            border-bottom: 2px solid #3d84ff;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .main-contain_con {
    margin-bottom: 140px;
    overflow: hidden;
    margin-top: 100px;

    .contain_up {
      background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
      background-size: 100% 100%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // height: 298px;

      .title_con {
        background: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px;

        .title_text_border {
          position: relative;
          min-width: 325px;
          height: 160px;
          border-top: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .left_circle {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(-100%, -50%);
          }

          .right_circle {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(100%, 50%);
          }

          .bt {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 151px;
            height: 40px;
            background: #3d84ff;
            border-radius: 20px;
            border-color: #3d84ff;
            opacity: none;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }

          .title_text {
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
          }

          .time_text {
            margin-top: 10px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    .contain_down {
      width: 100%;
      margin: 0px auto;
      // display: flex;
      // justify-content: center;

      .left_cont {
        width: calc(100% - 300px);
      }

      .right_cont {
        width: 300px;
        padding-left: 50px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
          padding: 20px 0px 10px;
        }

        .rec_course {
          width: 240px;
          height: 1055px;
          overflow-y: scroll;

          .course_block {
            padding-top: 20px;
            cursor: pointer;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .introduction {
        // margin-top: 25px;
        // border-radius: 4px;

        .elrow {
          height: 100%;
          width: 100%;

          .elcol-left {
            position: relative;
            width: 100%;

            .elimage {
              width: 100%;
              min-height: 200px;
              border-radius: 5px;
            }
            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                left: 15%;
              }
            }

            .statusimg {
              width: 80px;
              height: 80px;
              position: absolute;
              left: -2px;
              top: -2px;
            }
          }

          .elcol-right {
            height: 100%;
            padding-left: 10px;
            width: 100%;

            .intro-title {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              align-items: center;

              .item {
                margin-top: 10px;
                // margin-right: 100px;
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .elimage {
                  width: 20px;
                  height: 20px;
                }

                .elimage2 {
                  width: 23px;
                  height: 23px;
                }

                .item-name {
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  white-space: nowrap;
                }

                .item-text {
                  font-size: 14px;
                  font-weight: 500;
                  color: #333333;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .item:nth-child(1),
              .item:nth-child(2) {
                margin-top: 0px;
              }
            }

            .course-item {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: baseline;
              overflow: hidden;

              .left-title {
                width: 90px;
                white-space: nowrap;

                .coursename {
                  margin-left: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  margin-right: 10px;
                }
              }

              .right-text {
                position: relative;
                font-size: 14px;
                width: calc(100% - 90px);

                .text {
                  font-size: 14px;
                  line-height: 25px;
                  font-weight: 400;
                  color: #333333;
                  max-height: 150px;
                  overflow-y: auto;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                  word-wrap: break-word;
                }

                .morebt {
                  position: absolute;
                  font-size: 16px;

                  font-weight: 400;
                  color: #3d84ff;
                  bottom: 0px;
                  right: 0px;
                  user-select: none;
                  cursor: pointer;
                }
              }

              .right-text2 {
                margin-left: -80px;
              }
            }

            .bottom-title {
              margin-left: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .text {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                word-break: break-word;
                /* 英文-字母 */
                word-wrap: break-word;
                /* 英文-单词 */
                white-space: pre-line;
                /* 中文 */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bottom_con {
        width: 100%;
        min-height: 400px;

        .tabbt-contain {
          border-bottom: 1px solid #cccccc;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bt {
            // margin-right: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            user-select: none;
            cursor: pointer;

            .bt-icon {
              margin-right: 6px;
            }

            .bt-name {
              font-size: 14px;
              font-weight: 400;
              color: #222222;
            }

            .bt-name-active {
              color: #3d84ff;
            }
          }

          .btactive {
            border-bottom: 2px solid #3d84ff;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main-contain_con {
    margin-bottom: 140px;
    overflow: hidden;
    // margin-top: 100px;

    .contain_up {
      background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 298px;

      .title_con {
        background: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;

        .title_text_border {
          position: relative;
          min-width: 400px;
          height: 160px;
          border-top: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .left_circle {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(-100%, -50%);
          }

          .right_circle {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 16px;
            height: 16px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            transform: translate(100%, 50%);
          }

          .bt {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 151px;
            height: 40px;
            background: #3d84ff;
            border-radius: 20px;
            border-color: #3d84ff;
            opacity: none;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }

          .title_text {
            margin-top: 10px;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            color: #ffffff;
          }

          .time_text {
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    .contain_down {
      width: 1640px;
      margin: 0px auto;
      display: flex;
      justify-content: center;

      .left_cont {
        width: calc(100% - 300px);
      }

      .right_cont {
        width: 300px;
        padding-left: 50px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
          padding: 20px 0px 10px;
        }

        .rec_course {
          width: 240px;
          height: 1055px;
          overflow-y: scroll;

          .course_block {
            padding-top: 20px;
            cursor: pointer;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .introduction {
        margin-top: 25px;
        border-radius: 4px;

        .elrow {
          height: 100%;
          width: 100%;

          .elcol-left {
            position: relative;

            .elimage {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              text-align: center;
              ::v-deep .el-image__inner {
                position: relative;
                width: 100%;
                // left: 15%;
              }
            }
            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              text-align: center;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                // left: 15%;
              }
            }

            .statusimg {
              width: 80px;
              height: 80px;
              position: absolute;
              left: -2px;
              top: -2px;
            }
          }

          .elcol-right {
            height: 100%;
            padding-left: 20px;

            .intro-title {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              align-items: center;

              .item {
                margin-top: 10px;
                // margin-right: 100px;
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .elimage {
                  width: 20px;
                  height: 20px;
                }

                .elimage2 {
                  width: 23px;
                  height: 23px;
                }

                .item-name {
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  white-space: nowrap;
                }

                .item-text {
                  font-size: 14px;
                  font-weight: 500;
                  color: #333333;
                  white-space: nowrap;
                }
              }

              .item:nth-child(1),
              .item:nth-child(2) {
                margin-top: 0px;
              }
            }

            .course-item {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: baseline;
              overflow: hidden;

              .left-title {
                width: 90px;
                white-space: nowrap;

                .coursename {
                  margin-left: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  margin-right: 10px;
                }
              }

              .right-text {
                position: relative;
                font-size: 14px;
                width: calc(100% - 90px);

                .text {
                  font-size: 14px;
                  line-height: 25px;
                  font-weight: 400;
                  color: #333333;
                  max-height: 150px;
                  overflow-y: auto;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                  word-wrap: break-word;
                }

                .morebt {
                  position: absolute;
                  font-size: 16px;

                  font-weight: 400;
                  color: #3d84ff;
                  bottom: 0px;
                  right: 0px;
                  user-select: none;
                  cursor: pointer;
                }
              }

              .right-text2 {
                margin-left: -80px;
              }
            }

            .bottom-title {
              margin-left: 90px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .item {
                margin-top: 10px;
                // margin-right: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .score {
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666666;
                }

                .num {
                  font-size: 30px;
                  font-weight: 500;
                  color: #3d84ff;
                }

                .numname {
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }
              }
            }
          }
        }
      }

      .bottom {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bottom_con {
          width: 100%;
          min-height: 400px;

          .tabbt-contain {
            border-bottom: 1px solid #cccccc;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bt {
              // margin-right: 90px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-bottom: 6px;
              border-bottom: 2px solid transparent;
              user-select: none;
              cursor: pointer;

              .bt-icon {
                margin-right: 6px;
              }

              .bt-name {
                font-size: 18px;
                font-weight: 400;
                color: #222222;
              }

              .bt-name-active {
                color: #3d84ff;
              }
            }

            .btactive {
              border-bottom: 2px solid #3d84ff;
            }
          }
        }
      }
    }
  }
}
</style>
