<template>
  <!-- 课程大纲 -->
  <div class="main-contain">
    <div class="item-contain">
      <template v-if="courseinfo && courseinfo.length > 0">
        <div class="item" v-for="(item0, index) in courseinfo" :key="index">
          <div class="item-title">
            <div class="item-title-text">{{ item0.number }}</div>
            <div class="item-title-text">{{ item0.name }}</div>
          </div>
          <template v-if="item0.children && item0.children.length > 0">
            >
            <div class="item-item" v-for="(item1, index) in item0.children" :key="index">
              <div class="item-item-icon"></div>
              <div class="item-item-num">{{ item1.number }}</div>
              <div class="item-item-text">{{ item1.name }}</div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="is-empty" style="text-align: center;">
          <img :src="no_math" />
          <div
            style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;text-align: center;"
          >
            <span>暂无关联章节</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getoutlinelist } from "@/api/outline"
export default {
  name: "Courseintroduction",
  components: {},
  data () {
    return {
      courseinfo: "",
      no_math: require('@/assets/no_math1.png'),
    }
  },
  methods: {
    initdata (courseKey, id) {
      this.getoutlinelist({
        course_semester_sn: courseKey,
        test_id: id,
      })
    },
    getoutlinelist (params) {
      getoutlinelist(params)
        .then((response) => {
          this.courseinfo = response.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  .item-contain {
    // margin-top: 50px;
    // margin-left: 200px;
    .item {
      margin-top: 20px;
      .item-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item-title-text {
          margin-right: 30px;
          font-size: 20px;

          font-weight: bold;
          color: #666666;
        }
      }
      .item-item {
        margin-top: 20px;
        margin-left: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item-item-icon {
          width: 12px;
          height: 12px;
          background: #c6c6d3;
          border-radius: 50%;
        }
        .item-item-num {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 18px;

          font-weight: 400;
          color: #666666;
        }
        .item-item-text {
          font-size: 18px;

          font-weight: 400;
          color: #666666;
        }
      }
    }
    .tiptext {
      font-size: 20px;

      font-weight: bold;
      color: #666666;
    }
  }
}
</style>
