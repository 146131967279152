<template>
  <!-- 课程资料 -->
  <div class="main-contain">
    <div class="contain course_contain">
      <el-page-header
        @back="goBack"
        v-if="file_name != ''"
        :content="file_name"
      ></el-page-header>
      <el-table
        ref="singleTable"
        row-key="id"
        :data="tableData"
        highlight-current-row
        class="Table"
        v-loading="loading"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="文件名">
          <template slot-scope="scope">
            <div
              class="item-left"
              v-if="scope.row.file_type == 1"
              style="cursor: pointer"
              @click="lookbt(scope.row)"
            >
              <el-image
                v-if="!scope.row.file_type"
                class="elimage"
                :src="fileicon1"
              />
              <el-image
                v-else
                class="elimage"
                :src="file_icon(scope.row.ext)"
              />
              <div class="title">{{ scope.row.name }}</div>
            </div>
            <div
              class="item-left"
              v-else
              @click="seeFiles(scope.row)"
              style="cursor: pointer"
            >
              <el-image
                v-if="!scope.row.file_type"
                class="elimage"
                :src="fileicon1"
              />
              <el-image
                v-else
                class="elimage"
                :src="file_icon(scope.row.ext)"
              />
              <div class="title">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小" width="100">
          <template slot-scope="scope">
            <div class="item-middle" v-if="scope.row.file_type == 1">
              <div class="title">{{ scope.row.size }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="item-right">
              <div
                class="bt"
                v-if="scope.row.file_type"
                @click="lookbt(scope.row)"
              >
                查看
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page_total" v-if="isMobile == 1">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
      <div class="page_total" v-if="isMobile == 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :pager-count="5"
          :current-page="page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
    <Customdialog
      ref="customdialog"
      class="customdialog"
      :width="seeFilesWidth"
      type="form"
      title="预览"
      :showclose="true"
      @isDialogClose="close_dialog"
    >
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video
            class="video_view"
            ref="vueRef"
            :src="videoviewurl"
            controls
            controlslist="nodownload noplaybackrate"
            disablepictureinpicture
            width="100%"
            height="500"
            @contextmenu.prevent="handleRMouse"
          />
        </template>
        <template v-if="type == '图片'">
          <!-- <el-image class="image_view" :src="imageviewurl" fit="contain" /> -->
          <iframe
            class="iframe_cont"
            :src="imageviewurl"
            frameborder="0"
            ref="myiframe"
          ></iframe>
        </template>
        <template v-if="type == '其他'">
          <!-- <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              该文件暂时不支持在线预览，您可以下载后查看！
            </div>
          </div>-->
          <iframe
            class="iframe_cont"
            :src="file_url"
            frameborder="0"
            ref="myiframe"
          ></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <!-- <div class="pdf_con">
            <md-contract :url="fileurl" :visible="contractVisable" :showBtns="true" @handleModal="close">
            </md-contract>
          </div>-->
          <iframe
            class="iframe_cont"
            :src="fileurl"
            frameborder="0"
            ref="myiframe"
          ></iframe>
        </template>
        <div
          id="toolbar"
          :class="toolbar == false ? 'tool-box doc_1' : 'tool-box'"
          style="visibility: visible"
        >
          <div class="line-bar1"></div>
          <div class="full-box">
            <a class="full-btn1" @click="screen">
              <i class="el-icon-full-screen" style="font-size: 17px"></i>
              <span style="margin-left: 4px">全屏</span>
            </a>
            <!-- <a class="full-btn2" @click="fullScreen" style="display:none">
              <i class="iconfont"></i><span style="margin-left:4px">退出全屏</span>
            </a>-->
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download" v-if="!is_download"
          >下载</el-button
        >
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import pagTable from "@/components/Pagination/pagTable";
import Customdialog from "@/components/customdialog.vue";
import { getattachmentlist } from "@/api/attachment";
import { Base64 } from "js-base64";
import screenfull from "screenfull";
const request_base_path = require("@/utils/base_url");
export default {
  name: "virtualexperiment",
  components: {
    pagTable,
    Customdialog,
  },
  data() {
    return {
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
      fileurl: "",
      file_url: "",
      type: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      courseKey: "",
      tableData: [],
      per_page: 10, // 每页条数
      page: 1,
      total: 0,
      imageviewurl: "",
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp", "ico"],
      isdoc: ["word", "docx", "doc"],
      isVideo: [
        "mp4",
        "rmvb",
        "vob",
        "avi",
        "wmv",
        "flv",
        "mkv",
        "webm",
        "mov",
      ],
      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-video.png"),
      fileicon5: require("@/assets/fileicon/icon-img.png"),
      fileicon6: require("@/assets/fileicon/icon-node.png"),
      fileicon7: require("@/assets/fileicon/icon-audio.png"),
      fileicon8: require("@/assets/fileicon/icon-chapter.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),
      is_download: 0,
      itemObj: {},
      file_name: "",
      fullscreen: false, //全屏
      toolbar: false,
      loading: true,
      seeFilesWidth: "",
      isMobile: "",
    };
  },
  created() {
    const systemConfig = sessionStorage.getItem('systemConfig') || ""
    const systemInfo = JSON.parse(systemConfig)
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
    if (this._isMobile()) {
      this.isMobile = 0;
      this.seeFilesWidth = 100 + "%";
    } else {
      this.isMobile = 1;
      this.seeFilesWidth = 70 + "%";
    }
  },
  methods: {
    initdata(obj, courseKey) {
      console.log("课程资料", obj, courseKey);
      if (courseKey) {
        this.courseKey = courseKey;
        this.getattachmentlist();
      }
    },
    // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    handleRMouse(e) {
      e.preventDefault();
    },
    file_icon(val) {
      if (val == "pdf") {
        return this.fileicon2;
      } else if (this.isdoc.includes(val)) {
        return this.fileicon3;
      } else if (this.isVideo.includes(val)) {
        return this.fileicon4;
      } else if (this.isImg.includes(val)) {
        return this.fileicon5;
      } else if (val == "radio") {
        return this.fileicon7;
      } else if (val == "rar") {
        return this.fileicon9;
      } else if (val == "txt") {
        return this.fileicon11;
      } else if (val == "xlsx") {
        return this.fileicon12;
      } else if (val == "zip") {
        return this.fileicon13;
      } else {
        return this.fileicon10;
      }
    },
    async lookbt(item) {
      this.resetFill();
      await this.getTask(item.url);
      if (item.ext == "mp4" || item.ext == "avi" || item.ext == "flv") {
        this.type = "视频";
        this.videoviewurl = item.url;
        this.toolbar = false;
      } else if (this.isImg.includes(item.ext)) {
        this.type = "图片";
        let len = this.imageviewurlList.length;
        for (let i = 0; i < len; i++) {
          if (this.imageviewurlList[i] == item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i);
            let oldArr = this.imageviewurlList;
            this.imageviewurlList = [...newArr, ...oldArr];
            break;
          }
        }
        // console.log('2023', this.imageviewurlList);
        // alert('11111')
        let url = this.imageviewurlList.join("|");
        this.imageviewurl =
          this.picturesPreview + encodeURIComponent(Base64.encode(url));
        //window.open(this.imageviewurl,'_blank');
        this.toolbar = true;
      } else if (item.ext == "pdf") {
        this.type = "PDF";
        this.fileurl =
          this.filePreview + encodeURIComponent(Base64.encode(item.url));
        this.toolbar = true;
      } else {
        this.type = "其他";
        this.file_url =
          this.filePreview + encodeURIComponent(Base64.encode(item.url));

        // console.log('2023', item.url);
        this.toolbar = true;
      }

      this.itemObj = item;
      this.is_download = item.is_download;
      this.$refs.customdialog.dialogopenbt();
    },
    resetFill() {
      this.imageviewurl = "";
      this.fileurl = "";
      this.file_url = "";
    },
    getTask(data) {
      this.fileTask + data;
    },

    // 查看文件夹
    seeFiles(row) {
      this.loading = true;
      this.getattachmentlist(row);
      this.file_name = row.name;
    },
    // 返回
    goBack() {
      this.loading = true;
      this.getattachmentlist();
      this.file_name = "";
    },

    // 下载
    download() {
      let row = this.itemObj;
      //1.先创建一个a标签
      let a = document.createElement("a");
      a.target = "_bank";
      a.download = row.name;
      //2.给a标签的href赋上下载地址
      a.href = row.url;
      //3.让a标签不显示在视图中
      a.style.display = "none";
      //4.将a标签append到文档中
      document.body.appendChild(a);
      //5.a标签自点击
      a.click();
      //6.点击下载后，销毁这个节点
      document.body.removeChild(a);
    },
    close_dialog() {
      this.resetFill();
    },
    close_down() {
      this.$refs.customdialog.dialogclosebt();
      let _vueRef = this.$refs.vueRef;
      if (this.type === "视频") {
        _vueRef.pause(); //暂停
      }
      setTimeout(() => {
        this.itemObj = {};
        this.is_download = 0;
      }, 500);
    },
    //递归调取图片参数
    loop(list) {
      list.forEach((el) => {
        if (el.children.length > 0) {
          el.children.forEach((item) => {
            if (this.isImg.includes(item.ext)) {
              this.imageviewurlList.push(item.url);
            }
          });
          this.loop(el.children);
        }
      });
      return this.imageviewurlList;
    },

    convertSizeUnits(size) {
      if (size >= 1073741824) {
        size = Math.round((size / 1073741824) * 100) / 100 + "GB";
      } else if (size >= 1048576) {
        size = Math.round((size / 1048576) * 100) / 100 + "MB";
      } else if (size >= 1024) {
        size = Math.round((size / 1024) * 100) / 100 + "KB";
      } else {
        size = size + "字节";
      }
      return size;
    },

    getattachmentlist(row) {
      if (row != null) {
        var attachment_pid = row.id;
      }
      let params = {
        course_semester_sn: this.courseKey,
        type: 0,
        per_page: this.per_page, // 每页条数
        page: this.page,
        attachment_pid: attachment_pid,
      };
      this.imageviewurlList = [];
      getattachmentlist(params)
        .then((response) => {
          if (response.code == 0) {
            this.loading = false;
            this.tableData = response.data.data;
            response.data.data.forEach((el) => {
              this.$set(el, "size", this.convertSizeUnits(el.size));
              if (this.isImg.includes(el.ext)) {
                this.imageviewurlList.push(el.url);
              }
            });
            this.total = response.data.total;
            this.loop(this.tableData);
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    // 分页
    //页码发生改变时
    pageChange(val) {
      this.page = val;
      this.getattachmentlist();
    },

    // changeSize(data) {
    //   this.per_page = parseInt(data)

    // },
    // changePage(data) {
    //   this.page = parseInt(data)
    //   this.getattachmentlist()
    // },
    //全屏
    screen() {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .main-contain {
    margin-top: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // height: 200px;
    width: 100%;
    // position: relative;
    // overflow: hidden;

    .contain {
      // position: absolute;
      width: 100%;
      height: 100%;

      .Table {
        width: 100%;

        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 10px;

          .elimage {
            width: 25px;
            height: 25px;
          }

          .title {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #222222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // width: 55%;
          }
        }

        .item-middle {
          .title {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }

        .item-right {
          .bt {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        padding: 10px;

        .image_view {
          // height: 600px;
          width: 100%;
        }

        .pdf_con {
          // position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 20px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    ::v-deep .cell {
      display: flex !important;
    }
  }
  .page_total {
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .main-contain {
    margin-top: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // height: 200px;
    width: 100%;
    // position: relative;
    // overflow: hidden;

    .contain {
      // position: absolute;
      width: 100%;
      height: 100%;

      .Table {
        width: 100%;

        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 10px;

          .elimage {
            width: 25px;
            height: 25px;
          }

          .title {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #222222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // width: 55%;
          }
        }

        .item-middle {
          .title {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }

        .item-right {
          .bt {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        padding: 10px;

        .image_view {
          // height: 600px;
          width: 100%;
        }

        .pdf_con {
          // position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 20px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    ::v-deep .cell {
      display: flex !important;
    }
  }
  .page_total {
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .main-contain {
    margin-top: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // height: 200px;
    width: 100%;
    // position: relative;
    // overflow: hidden;

    .contain {
      // position: absolute;
      width: 100%;
      height: 100%;

      .Table {
        width: 100%;

        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 10px;

          .elimage {
            width: 25px;
            height: 25px;
          }

          .title {
            margin-left: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #222222;
          }
        }

        .item-middle {
          .title {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }

        .item-right {
          .bt {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        padding: 10px;

        .image_view {
          // height: 600px;
          width: 100%;
        }

        .pdf_con {
          // position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 20px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    ::v-deep .cell {
      display: flex !important;
    }
  }
  .page_total {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
